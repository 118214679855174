// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

//-----------------------|| APEXCHART ||-----------------------//

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

.css-1ifk55o-MuiStepIcon-text {
    fill: white;
}

.css-ll32jh-MuiTypography-root {
    font-size: 15px;
}

.css-fuymge-MuiTableCell-root {
    border-bottom: transparent;
}

.link_button {
    background-color: #b29962;
    color: white;
    margin-right: 10px;
   &:hover {
    background-color: #b29962;
   }
}

.csv_button {
    background-color: #b29962;
    color: white;
    border-radius: 4px;
    padding: 9px;
    text-decoration: none;
    margin-right: 10px;
    cursor: pointer;
}

.box {
	padding: 5px;
	border: 1px solid #ddd;
	border-radius: 5px;
	box-shadow: 0 8px 17px #eee;
	position: relative;
	background-color: #fff;
	font-family: "Roboto", sans-serif;
	background-size: 700px;
    width: 600px;
}
.rect1 {
	position: absolute;
	top: 0;
	right: 0;
	height: 200px;
	width: 200px;
	background: linear-gradient(to bottom right, #6c2f9e, #5590c8);
	border-radius: 50%;
	transform: rotate(45deg) translateY(-50%) translateX(-20%);
	z-index: -1;
}
.logo .img img {
	height: 50px;
	margin-right: 10px;
}
.logo .title {
	background: #999;
	font-weight: 300;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	margin-left: 100px;
}
.logo {
	font-size: 30px;
	padding: 30px;
	font-weight: 700;
	font-family: "Roboto", sans-serif;
	background: linear-gradient(to bottom right, #6c2f9e, #5590c8);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	text-transform: capitalize;
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom: 1px solid #eee;
}
.content {
	padding: 30px;
	display: block;
	align-items: center;
	justify-content: center;
}
.content .datename {
	// margin-bottom: 50px;
}
.content .signature {
	display: block;
}
.content .signature p {
	color: #999;
	font-size: 15px;
	border-top: 1px solid #999;
}
.content p {
	font-size: 12px;
}
.text {min-width: max-content}

.css-goc8xd-MuiNativeSelect-select-MuiInputBase-input-MuiOutlinedInput-input[multiple] {
    height: 300px;
}

//-----------------------|| PERFECT SCROLLBAR ||-----------------------//

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }
    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

//-----------------------|| ANIMATION KEYFRAMES ||-----------------------//

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}
